<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { DashboardSidebarLink } from '#ui-pro/types'
import type { Group } from '#ui/types'
// import { NotificationType } from '~/enums'
// import type { Notification } from '~/types'

const { t } = useI18n({
  useScope: 'local'
})

const rbac = useRbac()
const route = useRoute()
// const toast = useToast()
// const dashboardStore = useDashboardStore()

const adStore = useAdStore()
adStore.loadCountModerate()

// const appConfig = useAppConfig()
// const { isHelpSlideoverOpen } = useDashboard()

const ads = reactive({
  id: 'ad',
  label: t('link.ads'),
  icon: 'i-heroicons-clipboard-document-list',
  to: '/ads',
  badge: adStore.countModerate ?? undefined,
  tooltip: {
    text: t('link.ads'),
    shortcuts: ['G', 'A']
  }
} as DashboardSidebarLink)

const links = reactive([] as DashboardSidebarLink[])

const dashboard = {
  id: 'dashboard',
  label: t('link.dashboard.title'),
  icon: 'i-mdi-gauge',
  children: [],
  tooltip: {
    text: t('link.dashboard.title'),
    shortcuts: ['G', 'H']
  }
} as DashboardSidebarLink

if (rbac.can('call.click.list') || rbac.can('call.phone.list')) {
  dashboard.children?.push({
    label: t('link.dashboard.calls'),
    to: '/dashboard/calls'
  })
}

links.push(dashboard)

if (rbac.can('ad.list')) {
  links.push(ads)
}

if (rbac.can('village.list')) {
  links.push({
    id: 'villages',
    label: t('link.villages'),
    icon: 'i-heroicons-home-solid',
    to: '/villages',
    tooltip: {
      text: t('link.villages'),
      shortcuts: ['G', 'V']
    }
  } as DashboardSidebarLink)
}

if (rbac.can('developer.list')) {
  links.push({
    id: 'developers',
    label: t('link.developers'),
    icon: 'i-heroicons-home-modern',
    to: '/developers',
    tooltip: {
      text: t('link.developers'),
      shortcuts: ['G', 'D']
    }
  } as DashboardSidebarLink)
}

if (rbac.can('agency.list')) {
  links.push({
    id: 'agencies',
    label: t('link.agencies'),
    icon: 'i-heroicons-building-office',
    to: '/agencies',
    tooltip: {
      text: t('link.agencies'),
      shortcuts: ['G', 'A']
    }
  } as DashboardSidebarLink)
}

if (rbac.can('page.list')) {
  links.push({
    id: 'tags',
    label: t('link.pages'),
    icon: 'i-heroicons-table-cells',
    to: '/pages',
    tooltip: {
      text: t('link.pages'),
      shortcuts: ['G', 'P']
    }
  } as DashboardSidebarLink)
}

if (rbac.can('tag.list')) {
  links.push({
    id: 'tags',
    label: t('link.tags'),
    icon: 'i-heroicons-tag',
    to: '/tags',
    tooltip: {
      text: t('link.tags'),
      shortcuts: ['G', 'T']
    }
  } as DashboardSidebarLink)
}

if (rbac.can('banner.list')) {
  links.push({
    id: 'banners',
    label: t('link.banners'),
    icon: 'i-heroicons-photo',
    to: '/banners',
    tooltip: {
      text: t('link.banners'),
      shortcuts: ['G', 'B']
    }
  } as DashboardSidebarLink)
}

if (rbac.can('region.list') || rbac.can('city.list')) {
  const geo = {
    id: 'geo',
    label: t('link.geo.title'),
    icon: 'i-heroicons-globe-alt',
    defaultOpen: route.path.startsWith('/geo'),
    children: [],
    tooltip: {
      text: t('link.geo.title'),
      shortcuts: ['G', 'G']
    }
  } as any

  if (rbac.can('region.list')) {
    geo.children.push({
      label: t('link.geo.regions'),
      to: '/geo/regions'
    })
  }

  if (rbac.can('city.list')) {
    geo.children.push({
      label: t('link.geo.cities'),
      to: '/geo/cities'
    })
  }

  links.push(geo)
}

if (rbac.can('user.list')) {
  links.push({
    id: 'users',
    label: t('link.users'),
    icon: 'i-heroicons-user-group',
    to: '/users',
    tooltip: {
      text: t('link.users'),
      shortcuts: ['G', 'U']
    }
  } as DashboardSidebarLink)
}

const groups = [{
  key: 'links',
  label: t('link.go'),
  commands: links.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts }))
} as {}] as Group[]

//window.Echo.channel('ads').listen('.moderation.count', function (data: any) {
  //console.log('data', data)
  // const body = tg('notification.ad.moderate', data.count)
  //
  // toast.add({
  //   icon: 'i-heroicons-chat-bubble-bottom-center-text-16-solid',
  //   color: 'green',
  //   title: t('notification.title'),
  //   description: body,
  // })
  //
  // dashboardStore.addNotification({
  //   type: NotificationType.Ad,
  //   unread: true,
  //   body
  // } as Notification)
  //
  // adStore.$patch({
  //   countModerate: data.count
  // })
//})

watch(() => adStore.countModerate, (newVal) => {
  ads.badge = newVal ?? undefined
})
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
      <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
        <template #left>
          <TeamsDropdown />
        </template>
        <template #right>
          <LayoutSentryButton />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton
            :label="t('placeholder.search')"
          />
        </template>

        <UDashboardSidebarLinks
          :links="links"
          :ui="{
            badge: {
              color: 'red'
            }
          }"
        />

        <div class="flex-1" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <LayoutUserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <HelpSlideover />
    <NotificationsSlideover />

    <ClientOnly>
      <LazyUDashboardSearch
        :groups="groups"
        hide-color-mode
      />
    </ClientOnly>
  </UDashboardLayout>
</template>

<i18n lang="json">
{
  "ru": {
    "link": {
      "dashboard": {
        "title": "Дашбоард",
        "calls": "Звонки"
      },
      "ads": "Объявления",
      "villages": "Поселки",
      "banners": "Баннеры",
      "users": "Пользователи",
      "developers": "Застройщики",
      "agencies": "Агентства",
      "pages": "Страницы",
      "tags": "Теги",
      "geo": {
        "title": "Гео",
        "regions": "Регионы",
        "cities": "Города"
      },
      "go": "Перейти к"
    },
    "placeholder": {
      "search": "Поиск..."
    }
  }
}
</i18n>
