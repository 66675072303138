<script setup lang="ts">
const colorMode = useColorMode()

const logoSrc = computed(() => colorMode.value === 'dark' ? '/images/logo_dark.svg' : '/images/logo_light.svg')

</script>

<template>
  <UDropdown
    mode="hover"
    :items="[]"
    class="w-full"
    :ui="{ width: 'w-full' }"
    :popper="{ strategy: 'absolute' }"
    disabled
  >
    <UButton
      variant="link"
      class="w-full"
      to="/"
    >
      <NuxtImg
        :src="logoSrc"
        width="100"
      />
    </UButton>
  </UDropdown>
</template>
