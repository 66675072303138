<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { signOut } = useAuth()

const { t } = useI18n({
  useScope: 'local'
})

// const { isHelpSlideoverOpen } = useDashboard()
// const { isDashboardSearchModalOpen } = useUIState()
// const { metaSymbol } = useShortcuts()

const userStore = useUserStore()

const items = computed(() => [
  [{
    slot: 'account',
    label: '',
    disabled: true
  }], [{
    label: t('link.profile'),
    icon: 'i-heroicons-user-circle',
    to: '/profile'
  }], [{
    label: t('link.logout'),
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: async () => {
      await signOut({redirect: false})
      navigateTo('/auth/signin')
    }
  }]
])
</script>

<template>
  <UDropdown
    mode="hover"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="userStore.name"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar
            :src="userStore.data.image.url"
            :alt="userStore.name"
            size="2xs"
          />
        </template>

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>
          {{ t('signed_in') }}
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ userStore.name }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>

<i18n>
{
  "ru": {
    "link": {
      "profile": "Профиль",
      "logout": "Выйти"
    },
    "signed_in": "Авторизован как"
  }
}
</i18n>
