<script setup lang="ts">
import { BrowserClient, Feedback, getClient } from '@sentry/vue'

const client = getClient<BrowserClient>()
const feedback = client?.getIntegration(Feedback)

const onClick = () => {
  feedback?.openDialog()
}
</script>

<template>
  <UButton
    variant="link"
    color="white"
    icon="i-mdi-bug-outline"
    class="mt-1 -mr-2"
    size="xl"
    @click="onClick"
  />
</template>

